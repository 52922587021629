import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

const SettingsPopUp = ({
  onClose,
  handleLogout,
  selectedLanguage,
  setSelectedLanguage,
  subscriptionStatus,
}) => {
  const [activeTab, setActiveTab] = useState("outputOptions");
  const [outputLanguage, setOutputLanguage] = useState(
    localStorage.getItem("outputLanguage") || "en",
  );
  const [curriculum, setCurriculum] = useState(
    localStorage.getItem("curriculum") || "CAPS",
  );

  const [hasSubscription, setHasSubscription] = useState(false); // Track subscription status

  const popupRef = useRef();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLanguageChange = (event) => {
    setOutputLanguage(event.target.value);
  };

  const handleCurriculumChange = (event) => {
    setCurriculum(event.target.value);
  };

  // Close the popup when the user clicks outside of it
  const handleOutsideClick = (event) => {
    // Only close the popup if it's visible and the click is outside the popup container
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.id !== "open-popup-button" &&
      !event.target.classList.contains("user-profile-button") &&
      event.target.tagName != "a"
    ) {
      console.log("Outside click");
      onClose();
    }
  };

  const handleSubscribe = () => {
    // Implement your subscription logic here
    // Once the user successfully subscribes, setHasSubscription(true)
    console.log("Subscribe link clicked");
    if (subscriptionStatus === "Active") {
      setHasSubscription(true);
    }
  };

  useEffect(() => {
    // Add the outside click listener only when the popup is visible
    if (popupRef.current) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Clean up the outside click listener when the component unmounts or the popup is closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Save the selected values to local storage
    localStorage.setItem("outputLanguage", outputLanguage);
    localStorage.setItem("curriculum", curriculum);
  }, [outputLanguage, curriculum]);

  return (
    <div className="popup-container">
      <div className="popup" ref={popupRef}>
        <div className="popup-tabs">
          <button
            className={
              activeTab === "outputOptions" ? "active" : "close-button"
            }
            onClick={() => handleTabChange("outputOptions")}
          >
            Output Options
          </button>
          {/*<button
            className={activeTab === 'editProfile' ? 'active' : ''}
            onClick={() => handleTabChange('editProfile')}
          >
            Edit Profile
  </button>*/}
          <button
            className={activeTab === "accountSettings" ? "active" : ""}
            onClick={() => handleTabChange("accountSettings")}
          >
            Account Settings
          </button>
        </div>
        <div className="popup-content">
          {activeTab === "outputOptions" && (
            <div>
              <div className="form-field">
                <label htmlFor="outputLanguage">Output Language:</label>
                <select
                  id="outputLanguage"
                  value={outputLanguage}
                  onChange={handleLanguageChange}
                >
                  <option value="en">English</option>
                  <option value="af">Afrikaans</option>
                  {/* Add more language options here */}
                </select>
              </div>
              <div className="form-field">
                <label htmlFor="curriculum">Curriculum:</label>
                <select
                  id="curriculum"
                  value={curriculum}
                  onChange={handleCurriculumChange}
                >
                  <option value="CAPS">CAPS</option>
                  <option value="CBSE">CBSE</option>
                  <option value="IEB">IEB</option>
                  {/* Add more curriculum options here */}
                </select>
              </div>
            </div>
          )}

          {activeTab === "accountSettings" && (
            <div>
              <p>
                This feature is currently under development and will
                be made available for testing soon.
              </p>
              <div>
                {subscriptionStatus === "Active" ? (
                  <Link
                    className="link-cancel-subscription"
                    onClick={() => {
                      window.location.href = "mailto:admin@teacherstudioai.com?subject=Subscription Cancellation Request&body=I would like to cancel my subscription. Please assist me with the process.";
                    }}
                    style={{ color: 'red' }}
                  >
                    Cancel Subscription
                  </Link>
                ) : (
                  <Link
                    className="link-add-subscription"
                    to="/subscription"
                    onChange={console.log("Clicked Sub")}
                  >
                    Subscribe Now
                  </Link>
                )}
              </div>
            </div>
          )}

          {/* Add other tab contents here */}
        </div>

        {/*<button className="close-button" onClick={onClose}>
          Close
          </button>*/}
        <button className="close-button" onClick={handleLogout}>
          Sign Out
        </button>
      </div>
    </div>
  );
};

export default SettingsPopUp;
