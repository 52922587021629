import "../../assets/styles/styles.css";

import {
  GoogleAuthProvider,
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import { auth } from "../../configs/Firebase";

const Signin = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const popupRef = useRef();

  const handleSignin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }

    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log("Signed in successfully");
        setEmail("");
        setPassword("");
        setError("");
        navigate("/");
      })
      .catch((error) => {
        // Handle error response
        setError("Failed to sign in. Please check your credentials.");
        console.error(error);
      });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleForgotPassword = () => {
    // Implement the logic for password recovery/forgot password
    console.log("Forgot password");
  };

  const handleGoogleSignin = () => {
    const provider = new GoogleAuthProvider();

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // After setting persistence, proceed with sign-in
        signInWithPopup(auth, provider)
          .then((result) => {
            // Handle successful sign-in with Google
            console.log("Google sign-in success", result.user);
            navigate("/");
          })
          .catch((error) => {
            // Handle errors here
            setError("Failed to sign in with Google. Please try again.");
            console.error("Google sign-in error", error);
          });
      })
      .catch((error) => {
        // Handle errors in setting persistence here
        setError("Failed to set persistent auth. Please try again.");
        console.error("Persistence error", error);
      });
  };

  // Close the popup when the user clicks outside of it
  const handleOutsideClick = (event) => {
    // Only close the popup if it's visible and the click is outside the popup container
    console.log("fail");
    if (
      popupRef.current &&
      !popupRef.current.contains(event.target) &&
      event.target.id !== "open-popup-button" &&
      !event.target.classList.contains("user-profile-button")
    ) {
      console.log("here");
      onClose();
    }
  };

  useEffect(() => {
    // Add the outside click listener only when the popup is visible
    if (popupRef.current) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Clean up the outside click listener when the component unmounts or the popup is closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="popup-container">
      <div className="popup" ref={popupRef}>
        <div className="container">
          <h2 className="heading">Sign In</h2>
          <form onSubmit={handleSignin}>
            <input
              className="input-field"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="password-field">
              <input
                className="input-field"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="password-toggle-button"
                onClick={handleTogglePasswordVisibility}
              >
                {/*<img
      src={showPassword ? "https://img.icons8.com/fluency-systems-regular/24/visible--v1.png" : "https://img.icons8.com/material-outlined/24/visible--v1.png"}
  alt={showPassword ? 'Hide' : 'Show'}
    />*/}
                {showPassword ? <a>Hide</a> : <a>Show</a>}
              </button>
            </div>
            {/*<div className="remember-me">
            <label>
              <input
                className="checkbox"
                type="checkbox"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              Remember Me
            </label>
          </div>*/}
            <br />
            <button className="button" type="submit">
              Sign In
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
          <p>
            <Link className="link" to="/password-recovery">
              Forgot Password?
            </Link>
          </p>
          <p>
            Don't have an account yet?{" "}
            <Link className="link" to="/signup">
              Sign Up
            </Link>
          </p>
          <br />
          <button className="button button-google" onClick={handleGoogleSignin}>
            <span className="button-google-icon"></span>
            Sign In with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signin;
