import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../configs/Firebase";
import "../../assets/styles/styles.css";
import BetaOverlay from "../../components/BetaOverlay";

const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordRecovery = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrorMessage('Please enter your email');
      return;
    }

    try {
      await auth.sendPasswordResetEmail(email);

      setSuccessMessage('Password recovery email sent. Check your inbox.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Failed to send password recovery email. Please check your email address.');
      console.error(error);
    }
  };

  return (
    <div className="container">
      <h2 className="heading">Password Recovery</h2>
      <form onSubmit={handlePasswordRecovery}>
        <div className="form-group">
          <input
            className="input-field"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button className="button" type="submit">
          Send Recovery Email
        </button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <p>
        Remember your password?{" "}
        <Link className="link" to="/signin">
          Sign In
        </Link>
      </p>
      <BetaOverlay /> {/* Render the overlay */}
    </div>
  );
};

export default PasswordRecovery;
