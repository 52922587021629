import "../assets/styles/AppRouter.css";

import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import LandingPage from "../features/landing/LandingPage";
import MainApp from "../features/main/MainApp";
import PasswordRecovery from "../features/auth/PasswordRecovery";
import PrivateRoute from "./PrivateRouter";
import Signin from "../features/auth/Signin";
import Signup from "../features/auth/Signup";
import Spinner from "../components/Spinner";
import SubscriptionForm from "../features/subscription/SubscriptionForm";
import { auth } from "../configs/Firebase";

const AppRouter = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path="/welcome" element={<LandingPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/subscription" element={<SubscriptionForm />} />
      <Route
        path="/"
        element={user ? <MainApp /> : <Navigate to="/welcome" replace />}
      />
      <Route element={<PrivateRoute user={user} />}>
        <Route path="/app" element={<MainApp />} />
      </Route>
      <Route path="*" element={<Navigate to="/welcome" replace />} />
    </Routes>
  );
};

export default AppRouter;
