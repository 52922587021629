import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { auth } from "./configs/Firebase";

// Add an event listener to handle authentication state changes
auth.onAuthStateChanged((user) => {
  if (user) {
    // User is signed in, handle the authenticated state
    // For example, you can store the user in a state or dispatch an action
    console.log("User is signed in:", user);
  } else {
    // User is signed out, handle the signed out state
    // For example, you can clear the user state or show a sign-in page
    console.log("User is signed out");
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
