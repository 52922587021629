import React from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../configs/Firebase";

const PrivateRoute = ({ isAuthenticated, children }) => {
  isAuthenticated = auth.currentUser !== null;

  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }
  return children;
};

export default PrivateRoute;
