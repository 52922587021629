/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/styles/BetaOverlay.css";

import React from "react";

const BetaOverlay = () => {
  const handleContactAdmin = () => {
    window.location.href = "mailto:admin@teacherstudioai.com?subject=Account Enquirie&body=I would like to enquire about the following regarding my account.";
  };

  return (
    <div className="beta-overlay">
      <div className="overlay-content">
        <h3>Notice</h3>
        <p>
          For any account enquiries, please contact{" "}
          <a
            href="#"
            onClick={handleContactAdmin}
            style={{ color: "black" }}
          >
            admin@teacherstudioai.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default BetaOverlay;
