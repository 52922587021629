import "../../assets/styles/styles.css";

import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../configs/Firebase";
import { doc, getDoc } from "firebase/firestore";

import SettingsPopUp from "../settings/SettingsPopUp";
import Spinner from "../../components/Spinner";
import SubscriptionOverlay from "../../components/SubscriptionOverlay";
import axios from "axios";
import defaultAvatar from "../../assets/images/default-avatar.svg";
import htmlToDocx from "html-to-docx";
import { useNavigate } from "react-router-dom";

const MainApp = () => {
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("outputLanguage") || "en",
  );
  const [selectedCirriculum, setSelectedCurriculum] = useState(
    localStorage.getItem("curriculum") || "CAPS",
  );
  const [generatedReply, setGeneratedReply] = useState("");
  const [isSignedIn, setIsSignedIn] = useState(false); // Track the sign-in status
  const [userProfile, setUserProfile] = useState({
    username: "User",
    photoURL: defaultAvatar,
    //subscriptionStatus: "Free Trial"// Example: 'free', 'basic', 'premium'
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [downloadLink, setDownloadLink] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("Inactive");
  const [subscriptionType, setSubscriptionType] = useState("Free Trial");
  const [dateCreated, setDateCreated] = useState(Date);
  const [subscriptionPopupVisible, setSubscriptionPopupVisible] =
    useState(false);
  const currentDate = new Date();

  // Calculate the date and time 7 days ago
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  const gradeSubjects = {
    "Grade R": ["Mathematics", "English", "Home Language", "Life Skills"],
    "Grade 1": ["Mathematics", "English", "Home Language", "Life Skills"],
    "Grade 2": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
    ],
    "Grade 3": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
    ],
    "Grade 4": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
    ],
    "Grade 5": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
    ],
    "Grade 6": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 7": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 8": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 9": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 10": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 11": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
    "Grade 12": [
      "Mathematics",
      "English",
      "Home Language",
      "Life Skills",
      "Natural Sciences",
      "Social Sciences",
      "Technology",
      "Creative Arts",
    ],
  };

  const handleInputChange = (event, type) => {
    const value = event.target.value;
    if (type === "grade") {
      setGrade(value);
    } else if (type === "subject") {
      setSubject(value);
    } else if (type === "documentType") {
      setDocumentType(value);
    } else if (type === "input") {
      setInputValue(value);
    }
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    console.log("Grade:", grade);
    console.log("Subject:", subject);
    console.log("Document Type:", documentType);
    console.log("Input Value:", inputValue);

    // Check if the subscription should display a popup and prevent further action
    console.log(`Is user created more than 7 days ago? ${dateCreated < sevenDaysAgo}`);
    console.log(`The day 7 days ago is: ${sevenDaysAgo}`);
    console.log(`Account Created: ${dateCreated}`);
    if (
      subscriptionStatus !== "Active" || // Condition for non-active subscription
      (subscriptionType === "Free Trial" && dateCreated < sevenDaysAgo) // Condition for expired free trials
    ) {
      setSubscriptionPopupVisible(true); // Show the subscription popup
      return; // Exit the function to prevent generating chat
    }

    await generateChat();
  };

  const handleDownload = async () => {
    if (generatedReply) {
      const fileContent = generatedReply; // HTML content
      const convertedContent = await htmlToDocx(fileContent); // Convert HTML to docx

      const element = document.createElement("a");
      const file = new Blob([convertedContent], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      element.href = URL.createObjectURL(file);
      element.download = "generated_content.docx";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element); // Remove the temporary link
    }
  };

  const generateChat = async () => {
    setLoading(true);
    const modelEngine = "gpt-4";
    // const modelEngine = "gpt-4"; // Commented out to avoid confusion. Choose one engine.

    const assistantRules = `You are a content generator supporting a ${grade} teacher in South Africa. As the teacher's assistant, your mission is to craft a ${documentType} that adheres to the ${selectedCirriculum} Curriculum for the subject of ${subject}. The document should address a specific topic or objective determined by the user prompt. Please provide detailed information about the desired topic or objective to ensure accurate content generation. Express the content in the language with the ISO 639-1 Code '${selectedLanguage}'. Output the content in HTML format with the font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif. This document will be used in a docx file and printed for classroom use. Ensure the structure aligns with the user's expectations, and emphasize the importance of compliance with the selected curriculum. Feel free to provide examples or guidelines for clarity.`;

    const callSettings = {
      model: modelEngine,
      messages: [
        { role: "system", content: assistantRules },
        { role: "user", content: inputValue },
      ],
      max_tokens: 1000,
      n: 1,
      temperature: 0.2,
    };

    try {
      console.log(selectedCirriculum + " " + selectedLanguage); // Corrected variable name for consistency.
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        callSettings,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const { choices } = response.data;
      const reply = choices[0].message.content;

      setGeneratedReply(reply);
    } catch (error) {
      console.error("Failed to generate chat:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (grade && subject && documentType && inputValue) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [grade, subject, documentType, inputValue]);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
    console.log("vis");
  };

  useEffect(() => {
    // Function to fetch subscription type from Firebase
    const fetchSubscriptionType = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userId = user.uid;

          // Correctly reference the user's document using the modular syntax
          const userDocRef = doc(firestore, "users", userId);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            // Get the subscription type from the user document data
            const userData = userDoc.data();
            const status = userData.subscription_status;
            const subscriptionType = userData.subscription;

            const timestamp = userData.date_created;
            const milliseconds = (timestamp.seconds * 1000) + Math.round(timestamp.nanoseconds / 1000000);
            const dateCreated = new Date(milliseconds);

            console.log(dateCreated);

            console.log(status);
            setSubscriptionStatus(status);
            setSubscriptionType(subscriptionType);
            setDateCreated(dateCreated);
          } else {
            console.log("User document does not exist.");
          }
        }
      } catch (error) {
        console.error("Error fetching subscription type:", error);
      }
    };

    // Fetch subscription type when the component mounts
    fetchSubscriptionType();
  }, []); // Run this effect only once on component mount

  //const handleLanguageChange = (event) => {
  //  setSelectedLanguage(event.target.value);
  //};

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        console.log("Logged out");
        setIsSignedIn(false); // Update sign-in status
        navigate("/welcome");
      })
      .catch((error) => {
        console.error("Failed to log out", error);
      });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user); // Update sign-in status based on user existence

      if (user.displayName != null) {
        // User is authenticated
        setUserProfile({
          username: user.displayName,
          photoURL: defaultAvatar,
          // subscriptionStatus: user.
        });
        if (user.photoURL != null) {
          setUserProfile({
            username: user.displayName,
            photoURL: user.photoURL,
          });
        }
      }

      //else {
      // User is not authenticated, set default values
      // setUserProfile({
      //  username: "User",
      //   photoURL: defaultAvatar
      //  });
      //  }
    });

    return () => unsubscribe(); // Clean up the event listener
  }, []);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-brand">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="2rem"
            height="2rem"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M2443 4479 c-34 -4 -88 -18 -120 -31 -60 -25 -2003 -1151 -2059 -1193 -51 -39 -84 -117 -84 -194 0 -80 22 -131 76 -182 22 -20 173 -114 337 -209 l297 -172 0 -402 c0 -220 5 -432 10 -471 7 -47 24 -98 51 -154 70 -141 96 -161 585 -443 236 -135 461 -262 499 -281 85 -42 210 -83 302 -97 75 -12 267 -15 329 -5 185 30 287 72 564 231 124 72 330 191 459 265 276 159 326 201 390 330 27 56 44 107 51 154 5 39 10 250 10 471 l0 401 130 76 130 75 0 -579 0 -580 -29 -25 c-17 -14 -42 -50 -57 -80 -23 -48 -26 -63 -22 -124 19 -263 357 -332 480 -97 35 66 32 166 -7 236 -16 29 -40 60 -52 68 l-23 15 0 669 0 668 28 15 c40 21 99 86 117 128 8 21 15 66 15 102 0 83 -36 156 -100 202 -25 17 -488 289 -1030 603 -737 428 -1001 577 -1050 591 -81 23 -148 29 -227 19z m149 -297 c47 -17 1938 -1112 1938 -1122 -1 -4 -1163 -681 -1745 -1016 -208 -120 -242 -131 -324 -110 -29 7 -419 227 -1004 567 -526 305 -956 556 -956 559 -1 3 54 37 122 76 67 39 499 289 959 557 460 267 852 490 870 496 49 14 88 12 140 -7z m-837 -2187 c297 -172 565 -322 595 -332 81 -27 265 -25 341 4 31 11 169 86 305 165 654 381 823 478 838 484 15 6 16 -23 16 -317 0 -192 -4 -339 -10 -361 -25 -88 -48 -105 -490 -361 -467 -269 -522 -298 -635 -328 -112 -30 -288 -30 -400 0 -112 30 -166 58 -635 329 -442 255 -465 272 -490 360 -6 22 -10 169 -10 361 0 304 1 323 18 317 9 -4 260 -148 557 -321z" />
            </g>
          </svg>
          teacherstudio.
        </div>
        <div className="navbar-menu">
          {isSignedIn ? (
            <div className="navbar-item">
              <button
                className="user-profile-button"
                onClick={togglePopup} // Open/close the popup
              >
                <img
                  src={userProfile.photoURL}
                  alt="User Profile"
                  className="user-profile-photo"
                />
                {userProfile.username}
              </button>
            </div>
          ) : (
            <div className="navbar-item" onClick={() => navigate("/signin")}>
              Sign In
            </div>
          )}
        </div>
      </nav>

      <div className="container">
        {/*<h2 className="heading">Main App Screen</h2>*/}
        <form onSubmit={handleButtonClick}>
          <div className="form-field">
            <select
              id="grade"
              value={grade}
              onChange={(e) => handleInputChange(e, "grade")}
            >
              <option value="">Select Grade</option>
              {Object.keys(gradeSubjects).map((grade) => (
                <option key={grade} value={grade}>
                  {grade}
                </option>
              ))}
            </select>
          </div>
          <div className="form-field">
            <select
              id="subject"
              value={subject}
              onChange={(e) => handleInputChange(e, "subject")}
            >
              <option value="">Select Subject</option>
              {gradeSubjects[grade]?.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>
          <div className="form-field">
            <select
              id="documentType"
              value={documentType}
              onChange={(e) => handleInputChange(e, "documentType")}
            >
              <option value="">Select Document Type</option>
              <option value="Lesson Plan">Lesson Plan</option>
              <option value="Worksheet">Worksheet</option>
              <option value="Test">Test</option>
            </select>
          </div>
          <br />
          <div className="form-field">
            <input
              type="text"
              id="inputValue"
              value={inputValue}
              onChange={(e) => handleInputChange(e, "input")}
              placeholder="What are we learning about?"
            />
          </div>
          <br />
          <button className="button" type="submit" disabled={!formValid}>
            Generate
          </button>
        </form>
        {generatedReply && (
          <div>
            <div
              className="generated-reply-container"
              dangerouslySetInnerHTML={{ __html: generatedReply }}
            />
            <br></br>
            <button className="button" onClick={handleDownload}>
              Download
            </button>
          </div>
        )}
      </div>
      {/* Add the Popup component */}
      {popupVisible && (
        <>
          <SettingsPopUp
            onClose={() => {
              setPopupVisible(false);
            }}
            handleLogout={handleLogout}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            selectedCirriculum={selectedCirriculum}
            setSelectedCurriculum={setSelectedCurriculum}
            subscriptionStatus={subscriptionStatus}
          />
        </>
      )}
      {/* Add the Popup component */}
      {subscriptionPopupVisible && (
        <SubscriptionOverlay
          onClose={() => {
            setSubscriptionPopupVisible(false);
          }}
        />
      )}
      {/* Add the Spinner component */}
      {loading && <Spinner />}
    </div>
  );
};

export default MainApp;
