import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../configs/Firebase";
import crypto from "crypto";

const SubscriptionForm = () => {
  const merchantId = process.env.REACT_APP_PAYFAST_MERCHANT_ID;
  const merchantKey = process.env.REACT_APP_PAYFAST_MERCHANT_KEY;
  const returnUrl = 'http://localhost:3000/';
  const cancelUrl = 'http://localhost:3000/';
  const notifyUrl = process.env.REACT_APP_PIPEDREAM_URL;
  const passphrase = 'W3 ar3 only h3r3 to h3lp';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is logged in
        console.log(user.email);
        console.log(user.uid);
        const mPaymentId = generatePaymentId();
        const data = {
          merchant_id: merchantId,
          merchant_key: merchantKey,
          return_url: returnUrl,
          cancel_url: cancelUrl,
          notify_url: notifyUrl,
          email_address: user.email,
          m_payment_id: mPaymentId,
          amount: '100.00', // Set your desired amount here
          item_name: `Order#${mPaymentId}`,
          custom_str1: user.uid,
          subscription_type: '1', // Adjust as needed
          frequency: '3', // Adjust as needed
          cycles: '0', // Adjust as needed
        };

        data.signature = generateSignature(data, passphrase);

        const form = document.createElement('form');
        form.method = 'post';
        form.action = 'https://www.payfast.co.za/eng/process'; // Change to the PayFast URL for production

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
          }
        }
        document.body.appendChild(form);
        form.submit();
      }
    });

    return () => unsubscribe();
  }, []);

  const generateSignature = (data, passPhrase) => {
    let pfOutput = '';
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key] !== '') {
        pfOutput += `${key}=${encodeURIComponent(data[key]).replace(/%20/g, '+')}&`;
      }
    }

    let getString = pfOutput.slice(0, -1);
    if (passPhrase !== null) {
      getString += `&passphrase=${encodeURIComponent(passPhrase).replace(/%20/g, '+')}`;
    }
    console.log(crypto.createHash('md5').update(getString).digest('hex'));

    return crypto.createHash('md5').update(getString).digest('hex');
  };

  const generatePaymentId = () => {
    return Date.now().toString(); // Example: using timestamp as the payment ID
  };

  return null; // Render nothing, as the form has been removed
};

export default SubscriptionForm;