import "../../assets/styles/styles.css";

import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithPopup, updateProfile } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { auth, firestore } from "../../configs/Firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [username, setUsername] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await updateProfile(user, {
        displayName: username,
      })

      await createUserInFirestore(user.uid, email, username);

      // Reset form fields
      setEmail("");
      setPassword("");
      setUsername("");
      setError("");

      // Navigate to the MainApp component
      navigate("/");
    } catch (error) {
      // Handle error response
      setError("Failed to sign up. Please try again.");
      console.error(error);
    }
  };

  const createUserInFirestore = async (userUid, email, displayName) => {
    try {
      // Create a user document in Firestore
      await setDoc(doc(firestore, "users", userUid), {
        displayName: displayName,
        email: email,
        subscription: "Free Trial",
        subscription_status: "Active",
        date_created: serverTimestamp(),
      });

      console.log("Created in Firestore Successfully");
    } catch (error) {
      console.error("Failed to create user in Firestore", error);
    }
  };

  const handleGoogleSignup = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // Handle successful sign-up with Google
        console.log("Google signup success", result.user);

        // Create user in Firestore
        await createUserInFirestore(
          result.user.uid,
          result.user.email,
          result.user.displayName
        );

        navigate("/");
      })
      .catch((error) => {
        setError("Failed to sign up with Google. Please try again.");
        console.error("Google signup error", error);
      });
  };

  return (
    <div className="container">
      <h2 className="heading">Sign Up</h2>
      <form onSubmit={handleSignup}>
        <div className="form-group">
          <input
            className="input-field"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            className="input-field"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            className="input-field"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <br />
        <div className="form-group">
          <input
            className="input-field"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <br></br>
        <button className="button" type="submit">
          Sign Up
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
      <p>
        Already have an account?{" "}
        <Link className="link" to="/signin">
          Sign In
        </Link>
      </p>
      <p>
        {/* Link to navigate to the SubscriptionForm */}
        Want to subscribe?{" "}
        <Link className="link" to="/subscription">
          Subscribe Now
        </Link>
      </p>
      <br />
      <button className="button button-google" onClick={handleGoogleSignup}>
        <span className="button-google-icon"></span>
        Sign Up with Google
      </button>
      {/*<BetaOverlay /> {/* Render the overlay */}
    </div>
  );
};

export default Signup;
