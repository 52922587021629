import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/SubscriptionOverlay.css";

const SubscriptionOverlay = ({ onClose }) => {
  const handleOutsideClick = useCallback(
    (event) => {
      // Enhanced check for outside click using closer integration with React's event handling
      if (event.target.classList.contains("subscription-overlay")) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    // Add event listener for clicks outside the overlay content to close it
    window.addEventListener("click", handleOutsideClick);

    // Cleanup event listener on component unmount or onClose change
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick]);

  return (
    <div className="subscription-overlay" onClick={handleOutsideClick}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <h3>Subscribe for more!</h3>
        <p>
          Subscribe for exclusive features and unlimited access! Don't miss out
          – unlock the full potential of teacherstudio today!
        </p>
        <Link
          to="/subscription"
          className="link"
          onClick={() => console.log("Clicked Sub")}
        >
          Subscribe Now
        </Link>
      </div>
    </div>
  );
};

export default SubscriptionOverlay;
